
import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout/layout';
import '../stylesheets/templates/single-case-study.scss';
import htmlSerializer from '../utilities/htmlSerializer';
import { RichText } from 'prismic-reactjs';
import { CaseStudyShapes } from '../components/layout/svg';
import { ShowCategoryWithIcon } from '../components/layout/categories';


const CaseStudyTemplate = ({
  data: { case_study, header, footer },
  pageContext,
  Location,
}) => {

  return (
    <>
      <Layout headerStyle='light' title={case_study.data.meta_title.text} description={case_study.data.meta_description.text} header={header.data} footer={footer.data} >
        <div className="single-case-study">

          <div className="case-study-header">

            <CaseStudyShapes />

            <div className="container">
              {case_study.data.logo.url &&
                <div className="case-study-logo">
                  <img src={case_study.data.logo.url} alt={case_study.data.logo.alt} />
                </div>}

              <div className="case-study-categories text-center mt-5">
                {case_study.data.categories.map((category, index) => {

                  return <span key={index} style={{ color: case_study.data.category_color ? case_study.data.category_color : "#000" }}><ShowCategoryWithIcon name={category.category} color={case_study.data.category_color} /></span>

                })}
              </div>

              <div className="intro-text">
                <RichText render={case_study.data.intro_text.richText} htmlSerializer={htmlSerializer} />
              </div>
            </div>
          </div>

          {case_study.data.middle_image_section.url &&

            <div className="case-study-middle" style={{ backgroundColor: case_study.data.middle_image_section_background_color ? case_study.data.middle_image_section_background_color : "#000000" }}>
              <div className="container">
                <img src={case_study.data.middle_image_section.url} alt={case_study.data.middle_image_section.alt} />
              </div>
            </div>}

          <div className="case-study-body" style={{ backgroundColor: case_study.data.body_text_background_color ? case_study.data.body_text_background_color : "#00000" }}>

            <div className="container">

              <div className="row">
                <div className="col-xs-12 col-lg-3 py-5">

                  {case_study.data.client_name.text &&
                    <>
                      <h6>Client</h6>
                      <p>{case_study.data.client_name.text}</p>
                    </>}

                  {case_study.data.project_title.text &&
                    <>
                      <h6 className="pt-5">Project</h6>
                      <p>{case_study.data.project_title.text}</p>
                    </>}

                  {case_study.data.date.text &&
                    <>
                      <h6 className="pt-5">Date</h6>
                      <p>{case_study.data.date.text}</p>
                    </>}
                </div>

                <div className="case-study-body-text col-xs-12 col-lg-9 py-5">
                  <RichText render={case_study.data.body_text.richText} htmlSerializer={htmlSerializer} />
                </div>

              </div>

              {case_study.data.body_image.url &&
                <div className="case-study-body-image">
                  <img src={case_study.data.body_image.url} alt={case_study.data.body_image.alt} />
                </div>}


              {case_study.data.embed_video &&
                <div className="case-study-body-embed text-center">
                  <div className="w-100" dangerouslySetInnerHTML={{ __html: case_study.data.embed_video.html }} />
                </div>}

            </div>



          </div>

        </div>
      </Layout>
    </>
  )
}
export default CaseStudyTemplate

export const caseStudyQuery = graphql`
  query CaseStudy($slug: String) {
    case_study: prismicCaseStudy(uid: { eq: $slug }) {
      uid
      url
      data {
        project_title {
          text
        }
        middle_image_section_background_color
        middle_image_section {
          alt
          url
        }
        meta_description {
          text
        }
        meta_title {
          text
        }
        logo {
          alt
          url
        }
        intro_text {
          richText
        }
        featured_image {
          alt
          url
        }
        date {
          text
        }
        client_name {
          text
        }
        category_color
        categories {
          category
        }
        case_study_title {
          text
        }
        body_text_background_color
        body_text {
          richText
        }
        body_image {
          alt
          url
        }
        embed_video {
          html
        }
      }
      }

    header: prismicNavigation {
    ...FragmentHeader
    }

      footer: prismicFooter {
      ...FragmentFooter
    }

  }
`
